.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow: -moz-scrollbars-none; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Safari and Chrome */
}
